import { FooterComponentItem } from '@app-shared/components/footers/footer.model';
import { ClientConfig, DEFAULT_CLIENT_CONFIG } from './config.base';
import { navigationRoutes as BaseLinks, RouteParams } from './links.base';
import { QuantatecComponent } from '@app-shared/components/footers/quantatec/quantatec.component';

export const CLIENT_CONFIG: ClientConfig = {
  clientName: 'quantatec',
  ...DEFAULT_CLIENT_CONFIG,
  logo: {
    lightLogo: 'assets/quantatec/light-logo.png',
    darkLogo: 'assets/quantatec/dark-logo.png',
    alt: 'Quantatec',
    width: '130px',
    height: '30px',
  },
  showLandingPage: true,
  landingPageDetails: {
    backgroundImage: {
      src: 'assets/quantatec/quantatec-banner-bg.jpg',
    },
    brandImage: {
      src: 'assets/quantatec/light-logo.png',
    },
    headerImage: {
      src: 'assets/quantatec/light-logo.png',
    },
    tagline: ' ',
    footer: new FooterComponentItem(QuantatecComponent),
  },
};

export const navigationRoutes: RouteParams[] = CLIENT_CONFIG.allowedRoutes.map((x) => BaseLinks[x]);
